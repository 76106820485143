"use client";
import { useParams, usePathname, useRouter, useSearchParams } from "next/navigation";
import React, {  useEffect, useState } from "react";

import { useInitPublicHash } from "@/hooks";
import {
    generateUrl,
  getLoggedTokenPublic,
  setTokensPublic,
} from "@/utils/common";
import Loader from "@/components/common/loader";
import Header from "@/components/header";
import Footer from "@/components/footer";
import { Locale } from "@/i18n/i18n-config";

const InitPublicProvider = ({ children }: { children: React.ReactNode }) => {
    const [ready, setReady] = useState(false);
    const router = useRouter();
    const pathname = usePathname()
    const params = useParams<{ lang: Locale }>()
    const searchParams = useSearchParams();
    //check if has token
    const publicToken = getLoggedTokenPublic();
    const hashParam = searchParams.get('hash')
    let hash = publicToken;
    if(hashParam !== null) {
        hash = hashParam
    }
    const { data: initData, isSuccess } = useInitPublicHash({ hash, init: undefined });

    useEffect(() => {
        if (isSuccess) {
            setTokensPublic(initData?.data.hash);
            //remove search param
            if(pathname.includes('/fan/create')) {
                router.replace(generateUrl('/fan/create', params.lang))
            }
        }
    }, [isSuccess])
    useEffect(() => {
        if (initData) {
        //set vars
        const root = document.documentElement;
        root.style.setProperty('--interaction', initData.data.theme.table.text_accent);
        root.style.setProperty('--page-text', initData.data.theme.page.text);
        root.style.setProperty('--page-header', initData.data.theme.page.header);
        root.style.setProperty('--page-background', initData.data.theme.page.background);
        root.style.setProperty('--page-background-image', initData.data.theme.page.background_image ? `url(${initData.data.theme.page.background_image})` : 'none');
        root.style.setProperty('--table-text', initData.data.theme.table.text);
        root.style.setProperty('--table-text-accent', initData.data.theme.table.text_accent);
        root.style.setProperty('--table-background', initData.data.theme.table.background);
        root.style.setProperty('--badge-text-top', initData.data.theme.badge.text_top);
        root.style.setProperty('--badge-text-bottom', initData.data.theme.badge.text_bottom);
        setReady(true)
        }
    }, [initData]);
    if (!initData || ready === false) return <Loader height="500px" />

    return (
        <>
          <Header logo={initData?.data?.theme.page.logo} />{children}<Footer logo={initData?.data?.theme.page.logo_bottom}/>
        </>
    );
};

export { InitPublicProvider };
