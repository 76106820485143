import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

const Loader = ({ height = '100%' }: { height?: string }) => {
  const wrapper = { height };
  return (
    <div className={styles.wrapper} style={wrapper}>
      <svg
        className={cn(styles.loader)}
        fill="transparent"
        width="40"
        height="40"
        version="1.1"
        stroke="#005CFF"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="15" />
      </svg>
    </div>
  );
};

export default Loader;
